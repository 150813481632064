import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Grid,
  CardHeader,
  ToggleButtonGroup,
  ToggleButton,
  CardContent,
  CardActions,
  Switch,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useForm, Controller, FormProvider, useFormContext } from 'react-hook-form'
import { useNavigate, useOutletContext, useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import {
  ASSIGN_SLOT_TO_USER,
  CREATE_SEAT,
  LICENSES_QUERY,
  UNASSIGN_SLOT_FROM_USER,
  UPDATE_SEAT,
  UPDATE_USER_PRODUCT_PREFERENCES,
} from '../queries'
import { get } from 'lodash'
import { USER_QUERY_WITH_SLOTS } from '../constants/constants'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { isEmpty } from 'utils/isEmpty'
import CircleIcon from '@mui/icons-material/Circle'
import { useSelector } from 'react-redux'
import { InPersonTable } from './(manage-delivery)/in-person-table'
import { isRrpCertified, isIlsCertified, isSspCertified } from 'utils/permissions/permissionsLogic'
import { DialogTitleWithClose } from 'views/account/components/Wizard'
import InfoIcon from '@mui/icons-material/Info'

interface OutletContextType {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  updateSlots: () => void
}
interface PlaylistItemProps {
  label: string
  value: boolean
  category: string
  licenseId: number
  colour: string
}

interface PlaylistsProps {
  [key: string]: PlaylistItemProps
}

export const ManageDelivery = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading, loading, updateSlots } = useOutletContext<OutletContextType>()
  const { availableSspSlots, availableFocusSlots, availableRrpSlots } = useSelector((state) =>
    get(state, 'clients', {})
  )
  const authUser = useSelector((state) => get(state, 'auth.user', {}))
  const rrpCertified = isRrpCertified({ authUser })
  const sspCertified = isSspCertified({ authUser })
  const ilsCertified = isIlsCertified({ authUser })

  const [hasRemote, setHasRemote] = useState<null | boolean>(null)

  // get client data
  const { clientId: _clientId } = useParams()
  const clientId = parseInt(_clientId ?? '0', 10)

  // get all products for default value..
  const methods = useForm({
    defaultValues: {
      openConfirmation: true,
      pendingDeliveryPreference: '',
      deliveryPreference: '',
      hasSspSlots: false,
      hasRrpSlots: false,
      hasFocusSlots: false,
      email: '',
      programs: {},
      productPreferences: {},
      isInvitationMode: false,
      sspSlotStatus: '',
      rrpSlotStatus: '',
      focusSlotStatus: '',
      fullName: '',
      clientId,
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty, defaultValues },
  } = methods

  // watch variables
  const defaultPrograms = get(watch(), 'programs', {})
  const fullName = watch('fullName')
  const deliveryPreference = watch('deliveryPreference')
  const isRemoteClient = deliveryPreference === 'remote'
  const isInPersonClient = deliveryPreference === 'in-person'

  // query and set as default values
  const { data: licensesData, loading: loadingLicenses } = useQuery(LICENSES_QUERY, {
    variables: {
      filter: {
        isActive: true,
      },
      sort: [['product', 'order', 'ASC']],
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const initialRemoteTableValues = get(data, 'getLicenses', []).reduce(
        (accumulator, { product, id }) => {
          const programName = get(product, 'metadata.programName', '')
          const playlistItemName = product.metadata.playlistItemName
          const colour = get(product, 'metadata.clientManagementColour', '')
          const category = get(product, 'category', '')

          // base case: check for certifications
          const sspCertifiedFailed = !sspCertified && category === 'ssp'
          const rrpCertifiedFailed = !rrpCertified && category === 'rrp'
          const ilsCertifiedFailed = !ilsCertified && category === 'focus'

          const hasPassedCertification = !(
            sspCertifiedFailed ||
            rrpCertifiedFailed ||
            ilsCertifiedFailed
          )

          // add program to list and return
          if (programName && hasPassedCertification) {
            accumulator[programName] = {
              ...accumulator[programName],
              [product.id]: {
                label: playlistItemName,
                value: false,
                category,
                licenseId: id,
                colour,
              },
            }
          }
          return accumulator
        },
        {}
      )

      reset({
        ...watch(),
        programs: initialRemoteTableValues,
      })
    },
  })

  // get users licenses and populate accordingly..
  const { data: userData, loading: loadingUser } = useQuery(USER_QUERY_WITH_SLOTS, {
    variables: {
      filter: {
        ids: [clientId],
      },
    },
    skip: isEmpty(defaultPrograms),
    fetchPolicy: 'network-only',
    onCompleted: (userData) => {
      // check if we have slots
      const slots = get(userData, 'getUsers[0].slots', [])
      const sspSlot = slots.find(
        ({ status, category }) => status !== 'expired' && category === 'ssp'
      )
      const rrpSlot = slots.find(
        ({ status, category }) => status !== 'expired' && category === 'rrp'
      )
      const focusSlot = slots.find(
        ({ status, category }) => status !== 'expired' && category === 'focus'
      )

      const hasSspSlots = !!sspSlot
      const hasRrpSlots = !!rrpSlot
      const hasFocusSlots = !!focusSlot

      const sspSlotStatus = hasSspSlots ? sspSlot.status : ''
      const rrpSlotStatus = hasRrpSlots ? rrpSlot.status : ''
      const focusSlotStatus = hasFocusSlots ? focusSlot.status : ''

      // Extract user-specific data and overwrite default values
      const initialUserValues = get(userData, 'getUsers[0].seats', []).reduce(
        (accumulator, { productId, id, status }) => {
          // Find the program that matches the productId and update the value
          Object.keys(defaultPrograms).forEach((programName) => {
            if (defaultPrograms[programName][productId]) {
              accumulator[programName] = {
                ...accumulator[programName],
                [productId]: {
                  ...defaultPrograms[programName][productId],
                  value: status === 'active',
                  licenseId: id,
                  status,
                }, // Overwrite value to true
              }
            }
          })
          return accumulator
        },
        { ...defaultPrograms } // Start with the default values
      )

      // check if we have previously saved delivery preference, and infer otherwise
      const email = get(userData, 'getUsers[0].email', '')
      const fullName = get(userData, 'getUsers[0].fullName', '')
      const isInvitationMode = get(userData, 'getUsers[0].isInvitationMode', false)
      const productPreferences = get(userData, 'getUsers[0].productPreferences', {})
      const deliveryPreferencesByUser = get(
        userData,
        'getUsers[0].productPreferences.deliveryPreference',
        ''
      )

      // we default you to remote user if you have an email, or if you're invited
      const deliveryPreferencesByEmail = email || isInvitationMode ? 'remote' : 'in-person'
      const deliveryPreference = deliveryPreferencesByUser || deliveryPreferencesByEmail
      setHasRemote(Boolean(email || isInvitationMode))

      reset({
        deliveryPreference,
        programs: initialUserValues,
        hasSspSlots,
        hasRrpSlots,
        hasFocusSlots,
        email,
        productPreferences,
        isInvitationMode,
        sspSlotStatus,
        rrpSlotStatus,
        focusSlotStatus,
        fullName,

        // for confirmation window
        openConfirmation: false,
        pendingDeliveryPreference: deliveryPreference,
        clientId,
      })
    },
  })

  // loading hook
  useEffect(() => {
    if (licensesData && userData) {
      loading && setLoading(false)
    } else if (!loadingLicenses && !loadingUser) {
      loading && setLoading(false)
    } else {
      !loading && setLoading(true)
    }
  }, [licensesData, userData, loadingLicenses, loadingUser, loading])

  // mutations
  const [assignSlotToUser] = useMutation(ASSIGN_SLOT_TO_USER, {
    onError: () => {
      const message = 'Failed to assign program. Please refresh page and try again.'
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    },
  })
  const [unassignSlotFromUser] = useMutation(UNASSIGN_SLOT_FROM_USER)
  const [updateUserProductPreferences] = useMutation(UPDATE_USER_PRODUCT_PREFERENCES)
  const [createSeat] = useMutation(CREATE_SEAT, {
    onError: () => {
      const message = 'Failed to assign program. Please refresh page and try again.'
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    },
  })
  const [updateSeat] = useMutation(UPDATE_SEAT, {
    onError: () => {
      const message = 'Failed to assign program. Please refresh page and try again.'
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    },
  })

  // 1. if client does not have slot, assign first..
  const onSubmit = async (data) => {
    setLoading(true)

    // Clone the initial data to update it later
    const updatedData = { ...data }

    /**
     * Scenario 1: client is previously remote
     * Scenario 2: set productPreferences
     */

    try {
      // IN PERSON DELIVERY
      if (data.deliveryPreference === 'in-person') {
        // FOCUS
        if (data.hasFocusSlots !== defaultValues?.hasFocusSlots) {
          if (data.hasFocusSlots) {
            if (availableFocusSlots !== 0) {
              await assignSlotToUser({
                variables: {
                  userId: clientId,
                  productCategory: 'focus',
                },
              })
            }
          } else {
            if (defaultValues?.focusSlotStatus !== 'used') {
              await unassignSlotFromUser({
                variables: {
                  userId: clientId,
                  productCategory: 'focus',
                },
              })
              updatedData.focusSlotStatus = ''
            }
          }
        }

        // SSP
        if (data.hasSspSlots !== defaultValues?.hasSspSlots) {
          if (data.hasSspSlots) {
            if (availableSspSlots !== 0) {
              await assignSlotToUser({
                variables: {
                  userId: clientId,
                  productCategory: 'ssp',
                },
              })
            }
          } else {
            if (defaultValues?.sspSlotStatus !== 'used') {
              await unassignSlotFromUser({
                variables: {
                  userId: clientId,
                  productCategory: 'ssp',
                },
              })
              updatedData.sspSlotStatus = ''
            }
          }
        }

        // RRP
        if (data.hasRrpSlots !== defaultValues?.hasRrpSlots) {
          if (data.hasRrpSlots) {
            if (availableRrpSlots !== 0) {
              await assignSlotToUser({
                variables: {
                  userId: clientId,
                  productCategory: 'rrp',
                },
              })
            }
          } else {
            if (defaultValues?.rrpSlotStatus !== 'used') {
              await unassignSlotFromUser({
                variables: {
                  userId: clientId,
                  productCategory: 'rrp',
                },
              })
              updatedData.rrpSlotStatus = ''
            }
          }
        }

        // if client has been toggled to in-person, deactivate all programs
        for (const programName of Object.keys(data.programs)) {
          const program = data.programs[programName]

          for (const productId of Object.keys(program)) {
            const { value, licenseId, status } = program[productId]

            // If the value is true (program enabled), call createSeat
            if (value && status === 'active') {
              // unassign seat if we don't have available slot
              updateSeat({
                variables: {
                  seat: {
                    id: licenseId,
                    status: 'suspended',
                  },
                },
              })
              updatedData.programs[programName][productId].status = 'suspended'
              updatedData.programs[programName][productId].value = false
            }
          }
        }
      }

      /**
       * 1. assign slots
       * 2. assign seats
       */
      const isRemoteDelivery = data.deliveryPreference === 'remote'
      if (isRemoteDelivery) {
        for (const programName of Object.keys(data.programs)) {
          const program = data.programs[programName]

          for (const productId of Object.keys(program)) {
            const { value, category, licenseId, status } = program[productId]

            // If the value is true (program enabled), call createSeat
            if (value) {
              if (!updatedData.hasFocusSlots && category === 'focus') {
                if (availableFocusSlots) {
                  await assignSlotToUser({
                    variables: {
                      userId: clientId,
                      productCategory: 'focus',
                    },
                  })
                  updatedData.hasFocusSlots = true
                }
              }
              if (!updatedData.hasSspSlots && category === 'ssp') {
                if (availableSspSlots) {
                  await assignSlotToUser({
                    variables: {
                      userId: clientId,
                      productCategory: 'ssp',
                    },
                  })
                  updatedData.hasSspSlots = true
                }
              }
              if (!updatedData.hasRrpSlots && category === 'rrp') {
                if (availableRrpSlots) {
                  await assignSlotToUser({
                    variables: {
                      userId: clientId,
                      productCategory: 'rrp',
                    },
                  })
                  updatedData.hasRrpSlots = true
                }
              }

              // if we don't have an assigned slot, suspend all seats
              const hasSuccessfullyAssignedSspSlots = category === 'ssp' && updatedData.hasSspSlots
              const hasSuccessfullyAssignedRrpSlots = category === 'rrp' && updatedData.hasRrpSlots
              const hasSuccessfullyAssignedFocusSlots =
                category === 'focus' && updatedData.hasFocusSlots

              // unassign seat if we don't have available slot
              try {
                if (
                  (category === 'ssp' && !updatedData.hasSspSlots) ||
                  (category === 'rrp' && !updatedData.hasRrpSlots) ||
                  (category === 'focus' && !updatedData.hasFocusSlots)
                ) {
                  if (status === 'active') {
                    updateSeat({
                      variables: {
                        seat: {
                          id: licenseId,
                          status: 'suspended',
                        },
                      },
                    })
                    // Update the cloned data with the new status
                    updatedData.programs[programName][productId].status = 'suspended'
                    updatedData.programs[programName][productId].value = false
                  }
                }
              } catch (error) {
                console.error(`Error in manage delivery: ${error}`)
              }

              // only assign seat to active when above when above is true
              try {
                if (
                  !(
                    hasSuccessfullyAssignedFocusSlots ||
                    hasSuccessfullyAssignedSspSlots ||
                    hasSuccessfullyAssignedRrpSlots
                  )
                ) {
                  continue
                }

                if (status === 'suspended' && licenseId) {
                  await updateSeat({
                    variables: {
                      seat: {
                        id: licenseId,
                        status: 'active',
                      },
                    },
                  })
                  // Update the cloned data with the new status
                  updatedData.programs[programName][productId].status = 'active'
                } else if (status !== 'active') {
                  await createSeat({
                    variables: {
                      seat: {
                        licenseId, // Ensure productId is a number
                        userId: clientId, // Assuming you have clientId available
                      },
                    },
                  })
                  // Update the cloned data with the new status
                  updatedData.programs[programName][productId].status = 'active'
                }
              } catch (error) {
                console.error(`Failed to create seat for productId: ${productId}`, error)
              }

              // if we untoggled from in-person
            } else {
              // if value is false but we have an active seat, unassign..
              if (status === 'active') {
                updateSeat({
                  variables: {
                    seat: {
                      id: licenseId,
                      status: 'suspended',
                    },
                  },
                })
                // Update the cloned data with the new status
                updatedData.programs[programName][productId].status = 'suspended'
                updatedData.programs[programName][productId].value = false
              }
            }
          }
        }
      }
      // Use the reset function to update the form state with the new data
      await updateSlots()
      reset(updatedData)
      const productPreferences = {
        ...JSON.parse(JSON.stringify(data.productPreferences)),
        deliveryPreference: data.deliveryPreference,
      }
      updateUserProductPreferences({
        variables: {
          user: {
            id: clientId,
            productPreferences,
          },
        },
      })
      enqueueSnackbar('Update Successful', {
        variant: 'success',
        action: CloseSnackbarAction,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleReset = () => {
    reset()
  }
  const navigate = useNavigate()
  const handleEnableRemoteAccess = () => {
    navigate(`/clients/${clientId}`, {
      state: {
        isRemoteAccessEnabled: true,
        editMode: true,
      },
    })
  }

  const handleOpenConfirmationWindow = (value: string) => {
    setValue('pendingDeliveryPreference', value)
    setValue('openConfirmation', true)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        <Grid container px={2} py={1}>
          <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
            <Card variant="outlined">
              <CardHeader
                title={`Program Delivery Preferences for ${fullName}`}
                action={
                  <Tooltip
                    title={
                      deliveryPreference === 'remote' ? (
                        <span>
                          <strong>Remote Delivery:</strong> Clients can access enabled programs
                          remotely through the Unyte Health app. In-person sessions are still
                          possible using your provider account in the app.
                        </span>
                      ) : (
                        <span>
                          <strong>In-Person Delivery:</strong> Clients can only listen during
                          in-person sessions with you. Mobile app access is restricted, but they can
                          still complete assessments and access resources via MyUnyte.
                        </span>
                      )
                    }
                  >
                    <IconButton aria-label="info" color="primary">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                }
              />
              <CardContent>
                <Stack direction="row" spacing={1}>
                  <Controller
                    name="deliveryPreference"
                    control={control}
                    render={({ field }) => (
                      <ToggleButtonGroup
                        {...field}
                        color="primary"
                        exclusive
                        aria-label="Client Delivery Preferences Toggle"
                        onChange={(event, value) => {
                          if (value !== null) {
                            handleOpenConfirmationWindow(value)
                          }
                        }}
                      >
                        <ToggleButton value="in-person" sx={{ textTransform: 'none' }}>
                          In-person Delivery
                        </ToggleButton>
                        <ToggleButton
                          value="remote"
                          disabled={hasRemote !== null && !hasRemote}
                          sx={{ textTransform: 'none' }}
                        >
                          Remote Delivery
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  />
                  {hasRemote !== null && !hasRemote && (
                    <Button onClick={handleEnableRemoteAccess}>Enable Remote Access</Button>
                  )}
                </Stack>
                {isRemoteClient && <RemoteClientTable />}
                {isInPersonClient && <InPersonTable />}
              </CardContent>
              <CardActions>
                <Stack direction="row" justifyContent="space-between" width="100%" px={1} pb={4}>
                  <Box>
                    <Button type="submit" variant="contained" disabled={!isDirty}>
                      Save Changes
                    </Button>
                    <Button type="reset" disabled={!isDirty}>
                      Cancel Changes
                    </Button>
                  </Box>
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>
      <ConfirmationWindow />
    </FormProvider>
  )
}

/**
 * This component will appear when a provider attempts to switch a client's program delivery type
 */
function ConfirmationWindow() {
  const {
    getValues,
    setValue,
    reset,
    formState: { defaultValues },
  } = useFormContext() // Access form context
  const fullName = getValues('fullName') // Get form values for programs
  const openConfirmation = getValues('openConfirmation') // Get form values for programs
  const pendingDeliveryPreference = getValues('pendingDeliveryPreference') // Get form values for programs
  const clientId = getValues('clientId') // Get form values for programs
  const productPreferences = getValues('productPreferences') // Get form values for programs

  const [updateUserProductPreferences] = useMutation(UPDATE_USER_PRODUCT_PREFERENCES)

  const title =
    pendingDeliveryPreference === 'remote'
      ? 'Confirmation for Remote Delivery'
      : 'Confirmation for In-Person Delivery'

  const handleClose = () => {
    setValue('openConfirmation', false)
  }

  const handleConfirm = async () => {
    setValue('openConfirmation', false)
    setValue('deliveryPreference', pendingDeliveryPreference)

    updateUserProductPreferences({
      variables: {
        user: {
          id: parseInt(clientId),
          productPreferences: {
            ...JSON.parse(JSON.stringify(productPreferences)),
            deliveryPreference: pendingDeliveryPreference,
          },
        },
      },
    })
    reset({
      ...defaultValues,
      deliveryPreference: pendingDeliveryPreference,
      pendingDeliveryPreference,
    })
  }

  return (
    <Dialog onClose={handleClose} open={openConfirmation}>
      <DialogTitleWithClose onClose={handleClose}>{title}</DialogTitleWithClose>
      <DialogContent>
        <Stack spacing={3}>
          {pendingDeliveryPreference === 'in-person' && (
            <Typography variant="body1">
              You’ve selected In-Person Delivery for {fullName}. They will only be able to listen
              during in-person sessions, with no mobile app access. They can still complete
              assessments and access resources via MyUnyte.
            </Typography>
          )}
          {pendingDeliveryPreference === 'remote' && (
            <Typography variant="body1">
              You’ve selected Remote Delivery for {fullName}. They can listen to enabled programs
              remotely through the Unyte Health app. In-person sessions can still be delivered using
              your provider account in the app.
            </Typography>
          )}

          <Typography variant="body1">Do you want to proceed with this change?</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm Change</Button>
      </DialogActions>
    </Dialog>
  )
}

function RemoteClientTable() {
  const { getValues } = useFormContext() // Access form context
  const formValues = getValues('programs') // Get form values for programs
  const fullName = getValues('fullName')

  // get custom message by number of playlists selected
  const transformProgramsToSentence = (programs: any, fullName: string) => {
    const enabledPrograms: string[] = []

    for (const programType in programs) {
      for (const programId in programs[programType]) {
        const program = programs[programType][programId]
        if (program.value) {
          enabledPrograms.push(`${programType} ${program.label}`)
        }
      }
    }

    if (enabledPrograms.length === 0) {
      return `${fullName} does not have access to any programs through their Unyte Health.`
    }

    enabledPrograms.sort((a, b) => a.localeCompare(b))
    const programList = enabledPrograms.join(', ').replace(/, ([^,]*)$/, ' and $1')
    return `${fullName} has access to the following through their Unyte Health: ${programList}.`
  }

  // Example usage
  const sentence = transformProgramsToSentence(formValues, fullName)

  return (
    <Stack pt={4} spacing={2}>
      <Typography variant="body2">{sentence}</Typography>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ my: 2, height: 'calc(75vh - 300px)', overflowY: 'auto', paddignBottom: '80px' }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Program</TableCell>
              <TableCell>Playlist</TableCell>
              <TableCell>Enable Remote Delivery</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(formValues).map((programName) => (
              <Row
                key={programName}
                programName={programName}
                playlists={formValues[programName]}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

const Row = ({ programName, playlists }: { programName: string; playlists: PlaylistsProps }) => {
  const [open, setOpen] = React.useState(false) // Track if the row is expanded
  const { control, formState } = useFormContext() // Access form context
  const { availableSspSlots, availableRrpSlots, availableFocusSlots } = useSelector((state) =>
    get(state, 'clients', {})
  )

  // get default values
  const hasSspSlots = formState.defaultValues?.hasSspSlots
  const hasRrpSlots = formState.defaultValues?.hasRrpSlots
  const hasFocusSlots = formState.defaultValues?.hasFocusSlots

  // Toggle to open/close the row
  const toggleOpen = () => setOpen(!open)
  const color = get(Object.values(playlists)[0], 'colour', '#FFF')

  const hasAvailableSspSlots =
    (availableSspSlots || hasSspSlots) &&
    Object.values(playlists).some((item: PlaylistItemProps) => item.category === 'ssp')

  const hasAvailableRrpSlots =
    (availableRrpSlots || hasRrpSlots) &&
    Object.values(playlists).some((item: PlaylistItemProps) => item.category === 'rrp')

  const hasAvailableFocusSlots =
    (availableFocusSlots || hasFocusSlots) &&
    Object.values(playlists).some((item: PlaylistItemProps) => item.category === 'focus')

  const hasAvailability = hasAvailableFocusSlots || hasAvailableSspSlots || hasAvailableRrpSlots

  // get the number of playlists selected
  const selectedPlaylist = Object.values(playlists).filter(
    (item: PlaylistItemProps) => item.value === true
  )
  const selectedPlaylistCount = selectedPlaylist.length
  const totalPlaylistCount = Object.keys(playlists).length

  const transfromSelectedPlaylistCount = selectedPlaylistCount
    ? `${selectedPlaylistCount}/${totalPlaylistCount} Enabled`
    : 'None Enabled'

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={toggleOpen}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" disabled={!hasAvailability}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Stack justifyContent="flex-start" alignItems="center" direction="row" spacing={1}>
            <CircleIcon sx={{ color }} />
            <Typography noWrap variant="body2">
              {programName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell />
        <TableCell>
          <Typography noWrap variant="body2" ml={1.5}>
            {transfromSelectedPlaylistCount}
          </Typography>
        </TableCell>
        <TableCell align="left">{!hasAvailability && 'No available licenses'}</TableCell>
      </TableRow>

      {open &&
        Object.keys(playlists)
          .sort((a, b) => playlists[a].label.localeCompare(playlists[b].label)) // Sort by label
          .map((productId) => {
            const playlistItem = playlists[productId].label

            return (
              <TableRow key={productId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell colSpan={2} />
                <TableCell>
                  <Stack
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                  >
                    <CircleIcon sx={{ color }} />
                    <Typography noWrap variant="body2">
                      {playlistItem}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="left">
                  {!hasAvailability && 'No available licenses'}
                  {!!hasAvailability && (
                    <Controller
                      name={`programs.${programName}.${productId}.value`}
                      control={control}
                      render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                  )}
                </TableCell>
              </TableRow>
            )
          })}
    </>
  )
}
